import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Hero from '../components/Hero'
import InterestForm from '../components/InterestForm'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import logo_sentinel from '../images/logo_sentinel.svg'
import logo_predict from '../images/logo_predict.svg'
import logo_ssafe from '../images/logo_ssafe.svg'
import logo_coloq from '../images/logo_coloq.svg'
import logo_remote from '../images/logo_remote.svg'
import logo_redasa from '../images/logo_redasa.svg'
import logo_covesity from '../images/logo_covesity.svg'
import logo_comsuk from '../images/logo_comsuk.svg'
import logo_consult from '../images/logo_consult.png'
import consulticon from '../images/consulticon.jpeg'
import coloicon from '../images/coloicon.jpeg'
import comsicon from '../images/comsicon.jpeg'
import predicticon from '../images/predicticon.jpeg'
import redicon from '../images/redicon.jpeg'
import remoteicon from '../images/remoteicon.jpeg'
import ssafeicon from '../images/ssafeicon.jpeg'
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList } from "schema-dts";
import { Popover, OverlayTrigger, Overlay, Tooltip } from 'react-bootstrap';
import '../styles/bootstrap.css';

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "labperson_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth:1600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
function renderTooltip(props) {
  return (
    <Tooltip id="coloq" {...props}>
      PanSurg ColoQ is an international cross-sectional survey of current practice in the management of colorectal cancer.
    </Tooltip>
  );
}
function renderTooltip2(props) {
  return (
    <Tooltip id="comsuk" {...props}>
  COMS-UK is a cross-sectional survey assessing the impact of COVID-19 on the delivery of bariatric services around the UK.    </Tooltip>
  );
}
function renderTooltip3(props) {
  return (
    <Tooltip id="consult" {...props}>
  The CONSULT-19 survey has been developed by trainees for trainees, to understand the impact COVID will have on our career progression.    </Tooltip>
  );
}
function renderTooltip4(props) {
  return (
    <Tooltip id="predict" {...props}>
  PREDICT is collecting data on the impact of COVID-19 on surgical services in the UK.    </Tooltip>
  );
}
function renderTooltip5(props) {
  return (
    <Tooltip id="redasa" {...props}>
  We’re leveraging AI and machine learning to develop a realtime data synthesis and analysis tool to aid in understanding the global COVID-19 datasphere.</Tooltip>
  );
}
function renderTooltip6(props) {
  return (
    <Tooltip id="remote" {...props}>
PanSurg REMOTE is working with the HoloLens™ from Microsoft to deploy a mixed-reality headset capability </Tooltip>
  );
}
function renderTooltip7(props) {
  return (
    <Tooltip id="ssafe" {...props}>
SSAFE is longitudinal survey of healthcare workers  </Tooltip> );
}

export default ({data}) => {
  return (
  <>
   <JsonLd<BreadcrumbList>
      item={{
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
      "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "PanSurg",
      "item": "http://www.pansurg.org"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Research",
      "item": "http://www.pansurg.org/research"
    }] }} />
  <GatsbySeo title='PanSurg Research'
      description='Join our research streams to help improve surgical care on the frontline.'/>
  <Hero title="Research"
        subtitle="Join our research streams to help improve surgical care on the frontline"
        fluid={data.hero.childImageSharp.fluid}
        breadcrumbs
        activecrumb = 'Research'
  />

  <section className="section is-small" style={{margin:'0rem 0rem'}}>
    <div className="container ">

      <div className="content is-thin">
        <p>PanSurg is coordinating a wide variety of research projects to better understand
        the impact of the COVID-19 pandemic on healthcare delivery and optimise our response.</p>

        <p>Our projects rely on collaboration, so if you'd to get involved then we want to hear from
        you! See our projects below which we are recruiting for, or <Link to="/contact">contact us
        </Link>. Click on each logo for more information about the specific projects.</p>
      </div>
     <div style={{textAlign:'justify'}} className="has-text-centered"> 
     <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip}
  ><Link to="/coloq"><img src={coloicon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger>
    <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip2}
  ><Link to="/comsuk"><img src={comsicon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger>
     <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip3}
  ><Link to="/consult"><img src={consulticon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger> 
      <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip4}
  ><Link to="/predict"><img src={predicticon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger> 
      <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip5}
  ><Link to="/redasa"><img src={redicon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger> 
     <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip6}
  ><Link to="/remote"><img src={remoteicon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger> 
     <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 0 }}
    overlay={renderTooltip7}
  ><Link to="/ssafe"><img src={ssafeicon} width="100" style={{padding:'0.5rem'}}/></Link></OverlayTrigger> 
    </div>
    </div>
  </section>
  <hr className="is-divider"/>
  <section className="section is-small">
    <div className="container">
      <div className="columns">

        <div className="column">

          <div className="box content has-background-white-ter">
            <div className="has-text-centered">
              <Link to="/coloq"><img src={logo_coloq} width="200"/></Link>
            </div>

            <div>
              <p>PanSurg ColoQ is an international cross-sectional survey of current practice
              in the management of colorectal cancer. If you're a colorectal attending or
              consultant or regularly perform elective colorectal cancer resections in your
              practice we want to hear how COVID-19 has affected your management.</p>
              <div className="buttons is-centered">
                <Link className="button is-rounded is-darkBlue" to="/coloq">View data</Link>
                <a className="button is-rounded is-red" href="https://forms.gle/kPZU9UJNhgcasbnV8" target="_blank">Complete the survey</a>
              </div>
            </div>
          </div>

          <div className="box content has-background-white-ter">
            <div className="has-text-centered">
              <Link to="/predict"><img src={logo_predict} width="200"/></Link>
            </div>

            <div>
              <p>PREDICT is collecting data on the impact of COVID-19 on surgical services in the UK.</p>
              <p>Our aims are to:</p>
               <ul>
                <li>create live datasets for surgical patients during the pandemic</li>
                <li>generate real-time risk prediction models to inform decision making for surgical patients.</li>
                <li>provide guidance on current and ongoing surgical practice based upon
                data collected through this initiative.</li>
              </ul>
                <div className="buttons is-centered">
                  <Link className="button is-darkBlue is-rounded" to="/predict">Join PREDICT</Link>
                </div>
              </div>
            </div>

          <div className="box content has-background-white-ter">
            <div className="has-text-centered">
              <Link to="/remote"><img src={logo_remote} width="200"/></Link>
            </div>

            <div>
              <p>PanSurg REMOTE is working with the HoloLens™ from Microsoft to deploy
              a mixed-reality headset capability to aid in the response to COVID-19 across
              the full spectrum of care delivery and medical training.</p>
              <p>The aims of the project are:</p>
              <ul>
                <li>to establish the safety, feasibility and efficacy of HoloLens™ in supporting
                the delivery of clinical care</li>
                <li>to develop and evaluate the use of HoloLens™ in the delivery of real-time
                and on-demand healthcare education, training and supervision</li>
              </ul>

              </div>
            </div>

            <div className="box content has-background-white-ter">
              <div className="has-text-centered">
                <Link to="/covesity"><img src={logo_covesity} width="200"/></Link>
              </div>

              <div>
                <p>An emerging realtionship between the COVID-19 pandemic and obesity
                is explored in our COVesity research stream.</p>
                <p>COVID-19 has a disproportionate mortality in patients with obesity/metabolic
                syndome. "Lock-down" has resulted in a huge decrease in phyical activity
                levels amongst the general population in combination with poor food choices.
                Bariatric surgery services have been suspended.</p>
                <p>Are these factors combing to produce a perfect "COVesity" storm?</p>
              </div>
            </div>



           {/* } <div className="box content has-background-white-ter">
              <div className="has-text-centered">
                <img src={logo_sentinel} width="200"/>
              </div>

              <div>
                <p>Interest in real-time syndromic surveillance to understand disease propagation has greatly increased.
                Authorities worldwide are seeking alternative data streams, such as social media or crowdsourcing efforts,
                that can be processed in real-time, using latest developments in Machine Learning, Natural Language
                Processing and Social Media Analysis.</p>
              </div>
  </div> */}

          </div>

          <div className="column">

            <div className="box content has-background-white-ter">
              <div className="has-text-centered">
                <Link to="/redasa"><img src={logo_redasa} width="200"/></Link>
              </div>

              <div>
               <p className="subtitle"><strong>RE</strong>altime <strong>DA</strong>ta <strong>S</strong>ynthesis and <strong>A</strong>nalysis</p>

               <p>This highly innovative project is a collaboration between Imperial College London,
               Amazon Web Services, Cloudwick, MirrorWeb and the PanSurg Collaborative. We’re leveraging
               AI and machine learning to develop a realtime data synthesis and analysis tool to aid
               in understanding the global COVID-19 datasphere.</p>

               <p>We urgently need the help of data curators to drive this project.
               If you would like to contribute please get in touch using the form below.</p>
              </div>
            <br />
            <InterestForm name="interest_REDASA" />
          </div>

          <div className="box content has-background-white-ter">
            <div className="has-text-centered">
              <Link to="/ssafe"><img src={logo_ssafe} width="200"/></Link>
            </div>
            <div>
              <p className="subtitle"><strong>S</strong>taff and <strong>SA</strong>fety e<strong>F</strong>fects of <strong>E</strong>pidemics</p>
              <p>SSAFE is longitudinal survey of healthcare workers which aims to:</p>
              <ul>
                <li>investigate staff safety, psychological well-being, and burnout.</li>
                <li>create open-access data</li>
                <li>derive insights that assist workforce planning during and after the pandemic</li>
                <li>identify areas where healthcare workers may benefit from workplace wellbeing interventions.</li>
              </ul>
              <p>We want to include all healthcare professionals in this project - doctors, surgeons, nurses, healthcare asssitants
              and allied healthcare professionals.</p>

              <div className="buttons is-centered">
                <Link className="button is-rounded is-darkBlue" to="/ssafe_data">View data</Link>
                <Link className="button is-rounded is-red" to="/ssafe">Join SSAFE</Link>
              </div>
            </div>
          </div>

          <div className="box content has-background-white-ter">
            <div className="has-text-centered">
              <Link to="/comsuk"><img src={logo_comsuk} width="200"/></Link>
            </div>

            <p className="subtitle">Impact of <strong>C</strong>OVID-19 on <strong>O</strong>besity <strong>M</strong>anagement <strong>S</strong>ervices in the <strong>UK</strong></p>

            <div>
              <p>COMS-UK is a cross-sectional survey assessing the impact of COVID-19 on the
              delivery of bariatric services around the UK.</p>

              <p>We want to involve all healthcare professionals caring for bariatric patients in the UK.</p>
              <div className="buttons is-centered">
                <a className="button is-rounded is-red" href="https://www.surveymonkey.co.uk/r/COMS--UK" target="_blank">Complete the survey</a>
              </div>
            </div>
          </div>

          <div className="box content has-background-white-ter">
          <div className="has-text-centered">
          <Link to="/consult"><img src={logo_consult} width="200"/></Link>
              </div>
            <p className="subtitle"><strong>C</strong>onsequences<strong> ON</strong><strong> SU</strong>rgica<strong>L T</strong>raining from the COVID-<strong>19</strong> Pandemic</p>

            <div>
              <p>The CONSULT-19 survey has been developed by trainees for trainees, to understand the impact COVID will have on our career progression.</p>
              <p>This short survey allows us to better evaluate the loss of training through redeployment and cancellation of elective lists. Our findings will be shared with all relevant training bodies to help define training in the coming months.</p>
              <div className="buttons is-centered">
                <a className="button is-rounded is-red" href="https://www.surveymonkey.co.uk/r/consult19" target="_blank">Complete the survey</a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </section>

  <section className="section is-small">
    <div className="container">

      <div className="tile is-child box content has-background-white-ter">
        <p className="title is-size-4 has-text-red">Other Collaboratives</p>
        <p>PanSurg supports a variety of other COVID-19
        research collaboratives around the world.</p>
        <ul>
          <li><a href="https://globalsurg.org/covidsurg/">CovidSurg</a>: an international
          observational study of patients with COVID-19 who are undergoing surgery.</li>
        </ul>
        <ul>
          <li><a href="https://forms.gle/ED2bcM7bah8Shvb29/">COVIDTrauma</a>: An orthopaedic research collaborative in Africa
          to capture the effect of COVID-19 pandemic on orthopaedic trauma services in Africa.</li>
        </ul>
        <ul>
          <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSfPkoKTWT8qp7cQ2BOxIK0pqNrvA8IC1FTAj9RjxYJy-1zt_w/viewform">OGAA Collaborative</a>:
          Survey aiming to understand routine and current management of patients with oesophagogastric cancer.</li>
        </ul>
      </div>

    </div>
  </section>

  </>
  )
}
